import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { DateTime } from "luxon";
import TableComponents from "../components/TableComponents";
import "../styles/completedShipment.css";

const CompletedShipment = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/shipment/viewCompletedShipment`
        );
        const fetchedDatas = response.data;
        setDatas(fetchedDatas);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const data = useMemo(() => datas, [datas]);

  const columns = [
    {
      header: "ID",
      accessorKey: "_id",
    },
    {
      header: "Tracking Number",
      accessorKey: "trackingnumber",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
    {
      header: "Origin Country",
      accessorKey: "origincountry",
    },
    {
      header: "Origin State",
      accessorKey: "originstate",
    },
    {
      header: "Origin City",
      accessorKey: "origincity",
    },
    {
      header: "Origin Postal Code",
      accessorKey: "originpostalcode",
    },
    {
      header: "Sender Name",
      accessorKey: "senderfullname",
    },
    {
      header: "Sender Address",
      accessorKey: "senderaddress",
    },
    {
      header: "Sender Address #2",
      accessorKey: "senderaddresstwo",
    },
    {
      header: "Sender Email",
      accessorKey: "senderemail",
    },
    {
      header: "Sender Phone Number",
      accessorKey: "senderphonenumber",
    },
    {
      header: "Destination Country",
      accessorKey: "destinationcountry",
    },
    {
      header: "Destination State",
      accessorKey: "destinationstate",
    },
    {
      header: "Destination City",
      accessorKey: "destinationcity",
    },
    {
      header: "Destination Postal Code",
      accessorKey: "destinationpostalcode",
    },
    {
      header: "Reciver Full Name",
      accessorKey: "receiverfullname",
    },
    {
      header: "Reciver Address",
      accessorKey: "receiveraddress",
    },
    {
      header: "Reciver Address #2",
      accessorKey: "receiveraddresstwo",
    },
    {
      header: "Reciver Email",
      accessorKey: "receiveremail",
    },
    {
      header: "Reciver Phone Number",
      accessorKey: "receiverphonenumber",
    },
    {
      header: "Shipment Type",
      accessorKey: "shipmenttype",
    },
    {
      header: "Shipment Weight",
      accessorKey: "shipmentweight",
    },
    {
      header: "Shipment Length",
      accessorKey: "shipmentlength",
    },
    {
      header: "Shipment Width",
      accessorKey: "shipmentwidth",
    },
    {
      header: "Shipment Height",
      accessorKey: "shipmentheight",
    },
    {
      header: "Shipment Drop Off",
      accessorKey: "shipmentdropoffdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Shipment description",
      accessorKey: "shipmentdescription",
    },
    {
      header: "Pick up by",
      accessorKey: "pickupupdatedby",
    },
    {
      header: "Pick up update",
      accessorKey: "pickuplastupdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Document processed by",
      accessorKey: "documentprocessingupdatedby",
    },
    {
      header: "Document process update",
      accessorKey: "documentprocessinglastupdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Shipment processed by",
      accessorKey: "shipmentprocessingupdatedby",
    },
    {
      header: "Shipment process update",
      accessorKey: "shipmentprocessinglastupdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Intransit update by",
      accessorKey: "intransitupdatedby",
    },
    {
      header: "Intransit update",
      accessorKey: "intransitlastupdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "local delivery update by",
      accessorKey: "localdeliveryupdatedby",
    },
    {
      header: "local delivery update",
      accessorKey: "localdeliverylastupdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Delivery update by",
      accessorKey: "deliveryupdatedby",
    },
    {
      header: "Delivery update",
      accessorKey: "deliverylastupdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Finished update by",
      accessorKey: "finishedupdatedby",
    },
    {
      header: "Finished Update",
      accessorKey: "finishedlastupdate",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
    {
      header: "Shipment Status",
      accessorKey: "isshipmentactive",
    },
    {
      header: "Shipment Created",
      accessorKey: "created_at",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
  ];

  return (
    <div className='completedShipment'>
      {loading ? (
        <div className='loading-spin'>
          <Spin size='large' />
        </div>
      ) : (
        <TableComponents
          columns={columns}
          data={data}
          tableClassName={"table-container-shipment "}
        />
      )}
    </div>
  );
};

export default CompletedShipment;
