import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { SelectFeeder } from "../state/feedAuthSlice";
import { SelectAdmin } from "../state/adminAuthSlice";
import { DateTime } from "luxon";
import axios from "axios";
import { Spin } from "antd";
import TableSubComponent from "../components/TableSubComponent";
import "../styles/updateStatus.css";

const UpdateStatus = () => {
  const [trackNumber, setTrackNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shipment, setShipment] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("myPackage");

  const isFeedOnline = useSelector(SelectFeeder);
  const isAdminOnline = useSelector(SelectAdmin);

  const user = isFeedOnline ? isFeedOnline : isAdminOnline;

  const getShipment = async () => {
    try {
      setError(null);
      setFetchLoading(true);

      const shipmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/shipment/getShipment/${trackNumber}`
      );

      const shipment = shipmentResponse.data;
      setShipment(shipment);
      setFetchLoading(false);
    } catch (e) {
      console.log(e);
      setFetchLoading(false);
      setError("An error occurred. Please try again.");
    }
  };

  const updateShipment = async () => {
    const newDate = new Date(); // Create a new Date object

    const currentDate = DateTime.fromJSDate(newDate).toISO({
      includeOffset: false,
    });

    try {
      setError(null);
      setLoading(true);

      if (!trackNumber) {
        throw new Error("Please provide a valid tracking number.");
      }

      switch (selectedStatus) {
        case "pickUp":
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/shipment/updateShipment/${trackNumber}`,
            {
              status: selectedStatus,
              pickUpUpdatedBy: user.firstname,
              pickUpLastUpdate: currentDate,
            }
          );
          break;
        case "Document Processing":
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/shipment/updateShipment/${trackNumber}`,
            {
              status: selectedStatus,
              documentProcessingUpdatedBy: user.firstname,
              documentProcessingLastUpdate: currentDate,
            }
          );
          break;
        case "Shipment Processing":
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/shipment/updateShipment/${trackNumber}`,
            {
              status: selectedStatus,
              shipmentProcessingUpdatedBy: user.firstname,
              shipmentProcessingLastUpdate: currentDate,
            }
          );
          break;
        case "InTransit":
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/shipment/updateShipment/${trackNumber}`,
            {
              status: selectedStatus,
              intransitUpdatedBy: user.firstname,
              intransitLastUpdate: currentDate,
            }
          );
          break;
        case "Local Delivery":
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/shipment/updateShipment/${trackNumber}`,
            {
              status: selectedStatus,
              localDeliveryUpdatedBy: user.firstname,
              localDeliveryLastUpdate: currentDate,
            }
          );
          break;
        case "Delivered":
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/shipment/updateShipment/${trackNumber}`,
            {
              status: selectedStatus,
              deliveryUpdatedBy: user.firstname,
              deliveryLastUpdate: currentDate,
            }
          );
          break;
        case "Finished":
          await axios.patch(
            `${process.env.REACT_APP_BACKEND_URL}/shipment/updateShipment/${trackNumber}`,
            {
              status: selectedStatus,
              finishedUpdatedBy: user.firstname,
              finishedLastUpdate: currentDate,
            }
          );
          break;

        default:
          setError("An error occurred. Please try again.");
          break;
      }

      getShipment();
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setError("An error occurred. Please try again.");
    }
  };

  const handleCancel = () => {
    setShipment({});
    setTrackNumber("");
  };

  const shipmentData = useMemo(() => shipment, [shipment]);

  const shipmentColumn = [
    {
      header: "ID",
      accessorKey: "_id",
    },
    {
      header: "Tracking Number",
      accessorKey: "trackingnumber",
    },
    {
      header: "Sender fullname",
      accessorKey: "senderfullname",
    },
    {
      header: "Sender Address",
      accessorKey: "senderaddress",
    },
    {
      header: "Origin Country",
      accessorKey: "origincountry",
    },
    {
      header: "Reciver fullname",
      accessorKey: "receiverfullname",
    },
    {
      header: "Reciver Address",
      accessorKey: "receiveraddress",
    },
    {
      header: "Destination Country",
      accessorKey: "destinationcountry",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
  ];

  const NotificationComponent = () => {
    return (
      <div className='notificationContainer'>
        <div className='notificationBox'>
          <i className='fas fa-question-circle'></i>
          <p className='message '>{error}</p>
        </div>
      </div>
    );
  };

  return (
    <div className='updateStatus'>
      <div className='updateStatus-container-header'>
        <h3>Update Status</h3>
      </div>
      <div className='updateStatus-search-div'>
        <input
          type='text'
          placeholder='Search here'
          onChange={(e) => setTrackNumber(e.target.value)}
        />
        <button
          className='updateStatus-search-btn'
          onClick={getShipment}
          disabled={!trackNumber}
        >
          Search
        </button>
      </div>

      {fetchLoading ? (
        <div className='loading-spin'>
          <Spin size='large' />
        </div>
      ) : (
        <div>
          {!Object.keys(shipment).length ? (
            <div className='update-no-shipment'>No data available.</div>
          ) : (
            <TableSubComponent columns={shipmentColumn} data={shipmentData} />
          )}
        </div>
      )}

      <div className='updateStatus-update-div'>
        <p>Status update</p>
        <div className='updateStatus-select'>
          <select
            name='statusUpdate'
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value.trim())}
          >
            <option value='pickUp'>Pick up</option>
            <option value='Document Processing'>Document Processing</option>
            <option value='Shipment Processing'>Shipment Processing</option>
            <option value='InTransit'>InTransit</option>
            <option value='Local Delivery'>Local Delivery</option>
            <option value='Delivered'>Delivered</option>
            <option value='Finished'>Finished</option>
          </select>
          <button
            className='updateStatus-update-btn'
            onClick={updateShipment}
            disabled={Object.keys(shipment).length === 0 ? true : false}
          >
            Update
          </button>
          <button
            className='updateStatus-update-btn-cancel'
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      {loading && (
        <div className='loading-spin'>
          <Spin size='large' />
        </div>
      )}
      {error && <NotificationComponent />}
    </div>
  );
};

export default UpdateStatus;
