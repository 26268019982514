import React, { useEffect, useState, useMemo } from "react";
import { DateTime } from "luxon";
import axios from "axios";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectAdmin } from "../state/adminAuthSlice";
import { SelectFeeder } from "../state/feedAuthSlice";
import TableComponents from "../components/TableComponents";
import "../styles/settings.css";
import TableSubComponent from "../components/TableSubComponent";

const Settings = () => {
  const navigate = useNavigate();
  const isFeederOnline = useSelector(SelectFeeder);
  const isAdminOnline = useSelector(SelectAdmin);

  const [currentUser, setCurrentUser] = useState("");
  const [datas, setDatas] = useState([]);
  const [AdminDatas, setAdminDatas] = useState([]);
  const [priceLoading, setPriceLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [shipmentLoading, setshipmentLoading] = useState(false);
  const [staffLoading, setStaffLoading] = useState(false);
  const [adminLoading, setAdminLoading] = useState(false);
  const [staffDataLoading, setStaffDataLoading] = useState(false);
  const [adminDataLoading, setAdminDataLoading] = useState(false);
  const [priceError, setPriceError] = useState(null);
  const [userError, setUserError] = useState(null);
  const [shipmentError, setShipmentError] = useState(null);
  const [staffError, setStaffError] = useState(null);
  const [adminError, setAdminError] = useState(null);
  const [adminDataError, setAdminDataError] = useState(null);
  const [staffDataError, setStaffDataError] = useState(null);
  const [currentPrice, setCurrentPrice] = useState({});
  const [shipment, setShipment] = useState({});
  const [user, setUser] = useState({});
  const [admin, setAdmin] = useState({});
  const [staff, setStaff] = useState({});
  const [shipmentId, setShipmentId] = useState("");
  const [price, setPrice] = useState("");
  const [userId, setUSerId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [adminId, setAdminId] = useState("");

  console.log(currentUser);

  useEffect(() => {
    if (isFeederOnline) {
      setCurrentUser(isFeederOnline.firstname);
    } else {
      setCurrentUser(isAdminOnline.firstname);
    }

    const fetchStaffData = async () => {
      try {
        setStaffDataError(null);
        setStaffDataLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/dataFeeder/getFeeder`
        );
        const fetchedDatas = response.data;
        setDatas(fetchedDatas);
        setStaffDataLoading(false);
      } catch (error) {
        setStaffDataLoading(false);
        setStaffDataError("Error fetching data:", error.message);
      }
    };
    fetchStaffData();

    const fetchAdminData = async () => {
      try {
        setAdminDataError(null);
        setAdminDataLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/admin/getAdminUser`
        );
        const fetchedDatas = response.data;
        setAdminDatas(fetchedDatas);
        setAdminDataLoading(false);
      } catch (error) {
        setAdminDataLoading(false);
        setAdminDataError("Error fetching data:", error.message);
      }
    };
    fetchAdminData();

    const getPrice = async () => {
      try {
        setPriceError(null);
        setPriceLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/price/getprice`
        );
        const fetchedDatas = response.data[0];
        setCurrentPrice(fetchedDatas);
        setPriceLoading(false);
      } catch (error) {
        setPriceLoading(false);
        setPriceError("Error fetching data:", error.message);
      }
    };

    getPrice();
  }, [isFeederOnline, isAdminOnline]);

  const data = useMemo(() => datas, [datas]);

  const adminData = useMemo(() => AdminDatas, [AdminDatas]);

  const columns = [
    {
      header: "ID",
      accessorKey: "_id",
      footer: "ID",
    },
    {
      header: "User Name",
      accessorKey: "username",
    },
    {
      header: "First Name",
      accessorKey: "firstname",
    },
    {
      header: "Last Name",
      accessorKey: "lastname",
    },

    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone Number",
      accessorKey: "phonenumber",
    },
    {
      header: "User Created",
      accessorKey: "created_at",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
  ];

  const adminColumns = [
    {
      header: "ID",
      accessorKey: "_id",
      footer: "ID",
    },
    {
      header: "User Name",
      accessorKey: "username",
    },
    {
      header: "First Name",
      accessorKey: "firstname",
    },
    {
      header: "Last Name",
      accessorKey: "lastname",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone Number",
      accessorKey: "phonenumber",
    },
    {
      header: "User Created",
      accessorKey: "created_at",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
  ];

  const getIndividualUser = async () => {
    try {
      setUserError(null);
      setUserLoading(true);

      const shipmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/auth/getIndividualUser/${userId}`
      );

      const user = shipmentResponse.data;
      setUser(user);
      setUserLoading(false);
    } catch (e) {
      console.log(e);
      setUserLoading(false);
      setUserError("An error occurred. Please try again.");
    }
  };

  const getIndividualStaff = async () => {
    try {
      setStaffError(null);
      setStaffLoading(true);

      const shipmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/dataFeeder/getIndividualDataFeeder/${staffId}`
      );

      const staff = shipmentResponse.data;
      setStaff(staff);
      setStaffLoading(false);
    } catch (e) {
      console.log(e);
      setStaffLoading(false);
      setStaffError("An error occurred. Please try again.");
    }
  };

  const getIndividualAdmin = async () => {
    try {
      setAdminError(null);
      setAdminLoading(true);

      const shipmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getIndividualAdminUser/${adminId}`
      );

      const admin = shipmentResponse.data;
      setAdmin(admin);
      setAdminLoading(false);
    } catch (e) {
      console.log(e);
      setAdminLoading(false);
      setAdminError("An error occurred. Please try again.");
    }
  };

  const getShipmentById = async () => {
    try {
      setShipmentError(null);
      setshipmentLoading(true);

      const shipmentResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/shipment/getShipmentById/${shipmentId}`
      );

      const shipment = shipmentResponse.data;
      console.log(shipment);
      setShipment(shipment);
      setshipmentLoading(false);
    } catch (e) {
      console.log(e);
      setshipmentLoading(false);
      setShipmentError("An error occurred. Please try again.");
    }
  };

  const updatePrice = async () => {
    try {
      setPriceError(null);
      setPriceLoading(true);

      console.log("price" + currentPrice.price);
      console.log("updatedBy" + currentPrice.updatedby);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/priceHistory/createPriceHistory`,
        {
          price: currentPrice.price,
          updatedBy: currentPrice.updatedby,
        }
      );

      const shipmentResponse = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/price/updateprice/1`,
        {
          price: price,
          updatedBy: currentUser,
        }
      );

      await shipmentResponse.data;

      navigate("/");

      setPriceLoading(false);
      alert("New Price set successfully");
    } catch (e) {
      console.log(e);
      setPriceLoading(false);
      setPriceError("An error occurred. Please try again.");
    }
  };

  const deleteUser = async () => {
    try {
      setUserError(null);
      setUserLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inActiveUser/setUserInactive`,
        {
          userId: user._id,
          firstName: user.firstname,
          lastName: user.lastname,
          email: user.email,
          phoneNumber: user.phonenumber,
          country: user.country,
        }
      );

      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/auth/deleteuser/${userId}`
      );

      setUserLoading(false);
      alert("User Deleted successfully");
      setUSerId("");
      setUser({});
      setUserError(null);
    } catch (e) {
      console.log(e);
      setUserLoading(false);
      setUserError("An error occurred. Please try again.");
    }
  };

  const deleteStaff = async () => {
    try {
      setStaffError(null);
      setStaffLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inActiveFeedUser/setFeedUserInactive`,
        {
          userId: staff._id,
          userName: staff.userName,
          firstName: staff.firstName,
          lastName: staff.lastName,
          email: staff.email,
          phoneNumber: staff.phoneNumber,
        }
      );

      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/dataFeeder/deleteFeeder/${staffId}`
      );

      setDatas(
        datas.filter((data) => {
          return data._id !== staffId;
        })
      );

      setStaffLoading(false);
      alert("Staff Deleted successfully");
      setStaffId("");
      setStaff({});
      setStaffError(null);
    } catch (e) {
      console.log(e);
      setStaffLoading(false);
      setStaffError("An error occurred. Please try again.");
    }
  };

  const deleteAdmin = async () => {
    try {
      setAdminError(null);
      setAdminLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inActiveAdminUser/setAdminUserInactive`,
        {
          userId: admin._id,
          userName: admin.userName,
          firstName: admin.firstName,
          lastName: admin.lastName,
          email: admin.email,
          phoneNumber: admin.phoneNumber,
        }
      );

      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/deleteAdminUser/${adminId}`
      );

      setAdminDatas(
        AdminDatas.filter((AdminData) => {
          return AdminData._id !== adminId;
        })
      );

      setAdminLoading(false);
      setAdminId("");
      setAdmin({});
      setAdminError(null);
      alert("Admin Deleted successfully");
    } catch (e) {
      console.log(e);
      setAdminLoading(false);
      setAdminError("An error occurred. Please try again.");
    }
  };

  const deleteShipment = async () => {
    try {
      setShipmentError(null);
      setshipmentLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/inActiveShipment/setShipmentInactive`,
        {
          shipmentId: shipment[0]._id,
          userId: shipment[0].userid,
          trackingNumber: shipment[0].trackingnumber,
          originCountry: shipment[0].origincountry,
          originState: shipment[0].originstate,
          originCity: shipment[0].origincity,
          originPostalCode: shipment[0].originpostalcode,
          senderFullName: shipment[0].senderfullname,
          senderAddress: shipment[0].senderaddress,
          senderAddressTwo: shipment[0].senderaddresstwo,
          senderEmail: shipment[0].senderemail,
          senderPhoneNumber: shipment[0].senderphonenumber,
          destinationCountry: shipment[0].destinationcountry,
          destinationState: shipment[0].destinationstate,
          destinationCity: shipment[0].destinationcity,
          destinationPostalCode: shipment[0].destinationpostalcode,
          reciverFullName: shipment[0].receiverfullname,
          reciverAddress: shipment[0].receiveraddress,
          reciverAddressTwo: shipment[0].receiveraddresstwo,
          reciverEmail: shipment[0].receiveremail,
          reciverPhoneNumber: shipment[0].receiverphonenumber,
          shipmentType: shipment[0].shipmenttype,
          shipmentWeight: shipment[0].shipmentweight,
          shipmentLength: shipment[0].shipmentlength,
          shipmentWidth: shipment[0].shipmentwidth,
          shipmentHeight: shipment[0].shipmentheight,
          shipmentDropOffDate: shipment[0].shipmentdropoffdate,
          shipmentDescription: shipment[0].shipmentdescription,
          price: shipment[0].price,
          status: shipment[0].status,
          pickUpUpdatedBy: shipment[0].pickupupdatedby,
          pickUpLastUpdate: shipment[0].pickuplastupdate,
          documentProcessingUpdatedBy: shipment[0].documentprocessingupdatedby,
          documentProcessingLastUpdate:
            shipment[0].documentprocessinglastupdate,
          shipmentProcessingUpdatedBy: shipment[0].shipmentprocessingupdatedby,
          shipmentProcessingLastUpdate:
            shipment[0].shipmentprocessinglastupdate,
          intransitUpdatedBy: shipment[0].intransitupdatedby,
          intransitLastUpdate: shipment[0].intransitlastupdate,
          localDeliveryUpdatedBy: shipment[0].localdeliveryupdatedby,
          localDeliveryLastUpdate: shipment[0].localdeliverylastupdate,
          deliveryUpdatedBy: shipment[0].deliveryupdatedby,
          deliveryLastUpdate: shipment[0].deliverylastupdate,
          finishedUpdatedBy: shipment[0].finishedupdatedby,
          finishedLastUpdate: shipment[0].finishedlastupdate,
        }
      );

      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/shipment/deleteShipment/${shipmentId}`
      );

      setshipmentLoading(false);
      alert("Shipment Deleted successfully");
      setShipmentId("");
      setShipment({});
      setShipmentError(null);
    } catch (e) {
      console.log(e);
      setshipmentLoading(false);
      setShipmentError("An error occurred. Please try again.");
    }
  };

  const NotificationComponent = ({ error }) => {
    return (
      <div className='notificationContainer'>
        <div className='notificationBox'>
          <i className='fas fa-question-circle'></i>
          <p className='message '>{error}</p>
        </div>
      </div>
    );
  };

  const PriceTable = () => {
    return (
      <div className='price-div'>
        <table className='price-table'>
          <tbody>
            <tr className='price-table-row colored'>
              <td>Current Price</td>
              <td className='price-table-td'>
                <span>{currentPrice.price ?? ""}</span>
              </td>
            </tr>
            <tr className='price-table-row'>
              <td>Updated By</td>
              <td className='price-table-td'>
                <span>{currentPrice.updatedby ?? ""}</span>
              </td>
            </tr>
            <tr className='price-table-row colored'>
              <td>Last Update </td>
              <td className='price-table-td'>
                <span>
                  {currentPrice.updated_at
                    ? DateTime.fromISO(currentPrice.updated_at).toFormat(
                        "yyyy-MM-dd HH:mm:ss"
                      )
                    : ""}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const userData = useMemo(() => user, [user]);

  const userColumns = [
    {
      header: "ID",
      accessorKey: "_id",
    },
    {
      header: "First Name",
      accessorKey: "firstname",
    },
    {
      header: "Last Name",
      accessorKey: "lastname",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone Number",
      accessorKey: "phonenumber",
    },
    {
      header: "Country",
      accessorKey: "country",
    },
  ];

  const shipmentData = useMemo(() => shipment, [shipment]);

  const shipmentColumn = [
    {
      header: "ID",
      accessorKey: "_id",
    },
    {
      header: "Tracking Number",
      accessorKey: "trackingnumber",
    },
    {
      header: "Sender fullname",
      accessorKey: "senderfullname",
    },
    {
      header: "Sender Address",
      accessorKey: "senderaddress",
    },
    {
      header: "Origin Country",
      accessorKey: "origincountry",
    },
    {
      header: "Reciver fullname",
      accessorKey: "receiverfullname",
    },
    {
      header: "Reciver Address",
      accessorKey: "receiveraddress",
    },
    {
      header: "Destination Country",
      accessorKey: "destinationcountry",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
  ];

  const staffData = useMemo(() => staff, [staff]);

  const staffSubColumns = [
    {
      header: "ID",
      accessorKey: "_id",
      footer: "ID",
    },
    {
      header: "User Name",
      accessorKey: "username",
    },
    {
      header: "First Name",
      accessorKey: "firstname",
    },
    {
      header: "Last Name",
      accessorKey: "lastname",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone Number",
      accessorKey: "phonenumber",
    },
  ];

  const adminSubData = useMemo(() => admin, [admin]);

  const adminSubColumns = [
    {
      header: "ID",
      accessorKey: "_id",
      footer: "ID",
    },
    {
      header: "User Name",
      accessorKey: "username",
    },
    {
      header: "First Name",
      accessorKey: "firstname",
    },
    {
      header: "Last Name",
      accessorKey: "lastname",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone Number",
      accessorKey: "phonenumber",
    },
  ];

  return (
    <div className='settings'>
      <div className='settings-price'>
        <div className='settings-header'>
          <h3>Adjust Price</h3>
        </div>
        <PriceTable />
        <div className='settings-price-adjustment'>
          <p>Current Price</p>
          <p>
            <span>{currentPrice.price ?? ""}</span> Birr per weight
          </p>
        </div>
        <div className='price-adjustment-div'>
          <input
            type='number'
            placeholder='Enter new price '
            value={price}
            onChange={(e) => setPrice(e.target.value.trim())}
          />
          <button
            className='price-adjustment-btn'
            onClick={updatePrice}
            disabled={price.length === 0 ? true : false}
          >
            Set Price
          </button>
        </div>
        {priceLoading && (
          <div className='loading-spin'>
            <Spin size='large' />
          </div>
        )}
        {priceError && <NotificationComponent error={priceError} />}
      </div>

      <div className='settings-delete-user'>
        <div className='settings-delete-user-header'>
          <h3>Delete User</h3>
        </div>
        <div className='updateStatus-search-div'>
          <input
            type='text'
            placeholder='Search here'
            value={userId}
            onChange={(e) => setUSerId(e.target.value.trim())}
          />
          <button
            className='updateStatus-search-btn'
            onClick={getIndividualUser}
            disabled={userId.length === 0 ? true : false}
          >
            Search
          </button>
        </div>

        <div>
          {!Object.keys(user).length ? (
            <div className='TrackDataDisplay-no-shipment-display'>
              No data available.
            </div>
          ) : (
            <TableSubComponent columns={userColumns} data={userData} />
          )}
        </div>
        <div className='updateStatus-update-div'>
          <p>Delete User</p>
          <div className='updateStatus-select'>
            <button
              className='updateStatus-update-btn-cancel'
              onClick={deleteUser}
              disabled={Object.keys(user).length === 0 ? true : false}
            >
              Delete
            </button>
            <button
              className='updateStatus-update-btn'
              onClick={() => {
                setUSerId("");
                setUser({});
                setUserError(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        {userLoading && (
          <div className='loading-spin'>
            <Spin size='large' />
          </div>
        )}
        {userError && <NotificationComponent error={userError} />}
      </div>

      <div className='settings-delete-user'>
        <div className='settings-delete-user-header'>
          <h3>Delete Shipment</h3>
        </div>
        <div className='updateStatus-search-div'>
          <input
            type='text'
            placeholder='Search here'
            value={shipmentId}
            onChange={(e) => setShipmentId(e.target.value.trim())}
          />
          <button
            className='updateStatus-search-btn'
            onClick={getShipmentById}
            disabled={shipmentId.length === 0 ? true : false}
          >
            Search
          </button>
        </div>

        <div>
          {!Object.keys(shipment).length ? (
            <div className='TrackDataDisplay-no-shipment-display'>
              No data available.
            </div>
          ) : (
            <TableSubComponent columns={shipmentColumn} data={shipmentData} />
          )}
        </div>
        <div className='updateStatus-update-div'>
          <p>Delete Shipment</p>
          <div className='updateStatus-select'>
            <button
              className='updateStatus-update-btn-cancel'
              onClick={deleteShipment}
              disabled={Object.keys(shipment).length === 0 ? true : false}
            >
              Delete
            </button>
            <button
              className='updateStatus-update-btn'
              onClick={() => {
                setShipmentId("");
                setShipment({});
                setShipmentError(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        {shipmentLoading && (
          <div className='loading-spin'>
            <Spin size='large' />
          </div>
        )}
        {shipmentError && <NotificationComponent error={shipmentError} />}
      </div>

      <div className='display-staff-members'>
        <div className='display-staff-members-heading'>
          <h3>View Staff members</h3>
        </div>
        <div className='display-staff-members-table'>
          {staffDataLoading ? (
            <div className='loading-spin'>
              <Spin size='large' />
            </div>
          ) : (
            <TableComponents
              columns={columns}
              data={data}
              tableClassName={"table-container-shipment"}
            />
          )}
        </div>
        {staffDataLoading && (
          <div className='loading-spin'>
            <Spin size='large' />
          </div>
        )}
        {staffDataError && <NotificationComponent error={staffDataError} />}
        <div className='settings-delete-user'>
          <div className='settings-delete-user-header'>
            <h3>Delete Staff Member</h3>
          </div>
          <div className='updateStatus-search-div'>
            <input
              type='text'
              placeholder='Search here'
              value={staffId}
              onChange={(e) => setStaffId(e.target.value.trim())}
            />
            <button
              className='updateStatus-search-btn'
              onClick={getIndividualStaff}
              disabled={staffId.length === 0 ? true : false}
            >
              Search
            </button>
          </div>

          <div>
            {!Object.keys(staff).length ? (
              <div className='TrackDataDisplay-no-shipment-display'>
                No data available.
              </div>
            ) : (
              <TableSubComponent columns={staffSubColumns} data={staffData} />
            )}
          </div>
          <div className='updateStatus-update-div'>
            <p>Delete Staff Member</p>
            <div className='updateStatus-select'>
              <button
                className='updateStatus-update-btn-cancel'
                onClick={deleteStaff}
                disabled={Object.keys(staff).length === 0 ? true : false}
              >
                Delete
              </button>
              <button
                className='updateStatus-update-btn'
                onClick={() => {
                  setStaffId("");
                  setStaff({});
                  setStaffError(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {staffLoading && (
          <div className='loading-spin'>
            <Spin size='large' />
          </div>
        )}
        {staffError && <NotificationComponent error={staffError} />}
      </div>

      <div className='display-staff-members'>
        <div className='display-staff-members-heading'>
          <h3>View Admins</h3>
        </div>
        <div className='display-staff-members-table'>
          {adminDataLoading ? (
            <div className='loading-spin'>
              <Spin size='large' />
            </div>
          ) : (
            <TableComponents
              columns={adminColumns}
              data={adminData}
              tableClassName={"table-container-shipment"}
            />
          )}
        </div>
        {adminDataLoading && (
          <div className='loading-spin'>
            <Spin size='large' />
          </div>
        )}
        {adminDataError && <NotificationComponent error={adminDataError} />}
        <div className='settings-delete-user'>
          <div className='settings-delete-user-header'>
            <h3>Delete Admin</h3>
          </div>
          <div className='updateStatus-search-div'>
            <input
              type='text'
              placeholder='Search here'
              value={adminId}
              onChange={(e) => setAdminId(e.target.value.trim())}
            />
            <button
              className='updateStatus-search-btn'
              onClick={getIndividualAdmin}
              disabled={adminId.length === 0 ? true : false}
            >
              Search
            </button>
          </div>

          <div>
            {!Object.keys(admin).length ? (
              <div className='TrackDataDisplay-no-shipment-display'>
                No data available.
              </div>
            ) : (
              <TableSubComponent
                columns={adminSubColumns}
                data={adminSubData}
              />
            )}
          </div>
          <div className='updateStatus-update-div'>
            <p>Delete Admin</p>
            <div className='updateStatus-select'>
              <button
                className='updateStatus-update-btn-cancel'
                onClick={deleteAdmin}
                disabled={Object.keys(admin).length === 0 ? true : false}
              >
                Delete
              </button>
              <button
                className='updateStatus-update-btn'
                onClick={() => {
                  setAdminId("");
                  setAdmin({});
                  setAdminError(null);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {adminLoading && (
          <div className='loading-spin'>
            <Spin size='large' />
          </div>
        )}
        {adminError && <NotificationComponent error={adminError} />}
      </div>
    </div>
  );
};

export default Settings;
