import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { DateTime } from "luxon";
import TableComponents from "../components/TableComponents";
import { Spin } from "antd";
import "../styles/viewUsers.css";

const ViewUsers = () => {
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/getUser`
        );
        const fetchedDatas = response.data;
        setDatas(fetchedDatas);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  const data = useMemo(() => datas, [datas]);

  const columns = [
    {
      header: "ID",
      accessorKey: "_id",
      footer: "ID",
    },
    {
      header: "First Name",
      accessorKey: "firstname",
    },
    {
      header: "Last Name",
      accessorKey: "lastname",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Phone Number",
      accessorKey: "phonenumber",
    },
    {
      header: "Country",
      accessorKey: "country",
    },
    {
      header: "User Created",
      accessorKey: "created_at",
      cell: (info) =>
        DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
    },
  ];

  return (
    <div className='viewUsers'>
      {loading ? (
        <div className='loading-spin'>
          <Spin size='large' />
        </div>
      ) : (
        <TableComponents
          columns={columns}
          data={data}
          tableClassName={"table-container-shipment"}
        />
      )}
    </div>
  );
};

export default ViewUsers;
